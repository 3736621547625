import { render, staticRenderFns } from "./HomeWidgetPatients.vue?vue&type=template&id=f948f0ca&scoped=true&"
import script from "./HomeWidgetPatients.vue?vue&type=script&lang=ts&"
export * from "./HomeWidgetPatients.vue?vue&type=script&lang=ts&"
import style0 from "./HomeWidgetPatients.vue?vue&type=style&index=0&id=f948f0ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f948f0ca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_a266eb3209234f15fc02fe57f54d2cc5/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText})
