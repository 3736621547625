






































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useDialog } from '@/utils/dialog.utils';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

type AppProvider = 'apple' | 'google';

export default defineComponent({
  name: 'DialogDownloadMobileApp',
  setup() {
    const dialogId: DialogId = 'download-mobile-app';
    const selectedQrCodeProvider = ref<AppProvider>();
    const bus = usePodocoreModule('bus');
    const { dialog, open, close } = useDialog();

    bus.useEventSubscriber(bus.events.openDialog, (event) => {
      if (event.payload.id === dialogId) open(dialogId);
    });

    const closeDialog = () => {
      bus.publish(
        bus.events.closeDialog({
          id: dialogId
        })
      );
      selectedQrCodeProvider.value = undefined;
      close();
    };

    return {
      dialogId,
      dialog,
      open,
      closeDialog,
      selectedQrCodeProvider
    };
  }
});
