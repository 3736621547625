




































// Import vendors ----------------------------------------------------------------------------------
import { computed, Data, defineComponent } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
import { startsWith } from 'lodash';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'HomeWidgetPatients',
  setup(_, { root, attrs }) {
    const { state } = usePodocoreModuleService('workspaces');

    const iconAttributes = computed(() => {
      return Object.entries(attrs).reduce((accumulator, [key, value]) => {
        if (startsWith(key, 'icon')) {
          accumulator[key.replace(/^icon\./, '')] = value;
        }
        return accumulator;
      }, {} as Data);
    });

    function navigateToPatients() {
      root.$router.push({
        name: 'patients',
        params: !(state.value.context as any).current._sumPatients ? { createPatient: 'true' } : {}
      });
    }

    return {
      // Values
      state,
      iconAttributes,
      // Methods
      navigateToPatients
    };
  }
});
