





























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
import { useLocalStorage } from '@vueuse/core';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'HomeWidgetOptions',
  props: {
    currentPlanName: {
      type: String,
      required: true
    }
  },
  setup() {
    const hideCard = useLocalStorage('hideHomeWidgetRunOptionCard', false);

    function hideHomeWidgetRunOptionCard() {
      hideCard.value = !hideCard.value;
    }

    return {
      hideHomeWidgetRunOptionCard,
      hideCard
    };
  }
});
