






























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import types ------------------------------------------------------------------------------------
import { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'HomeWidget',
  props: {
    widget: {
      type: Object,
      required: true
    },
    keepIllustration: {
      type: Boolean,
      default: false
    },
    fullHeight: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const { cdn } = useCDN();
    const { trackSuccess } = useAnalytics();
    const busModule = usePodocoreModule('bus');

    const openDialog = (id: DialogId) => {
      busModule.publish(
        busModule.events.openDialog({
          id
        })
      );
    };

    const onActionClick = (action: any) => {
      trackSuccess(action.tracker, { value: true });
      if (action.dialog) openDialog(action.dialog);
    };

    return {
      // Utils
      cdn,
      trackSuccess,
      // Methods
      onActionClick
    };
  }
});
