




















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'HomeGetStartedCheckbox',
  props: {
    checkbox: {
      type: Object,
      required: true
    }
  },
  setup(properties, { root }) {
    function navigate() {
      if (properties.checkbox.to && !properties.checkbox.disabled) {
        root.$router.push({ name: properties.checkbox.to.name, params: properties.checkbox.to.params });
      }
    }
    return {
      navigate
    };
  }
});
