import { render, staticRenderFns } from "./HomeWidget.vue?vue&type=template&id=47ba1706&scoped=true&"
import script from "./HomeWidget.vue?vue&type=script&lang=ts&"
export * from "./HomeWidget.vue?vue&type=script&lang=ts&"
import style0 from "./HomeWidget.vue?vue&type=style&index=0&id=47ba1706&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ba1706",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_a266eb3209234f15fc02fe57f54d2cc5/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VImg})
