


























// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import CompositeCard from '../composite/CompositeCard.vue';
import HomeGetStartedCheckbox from './HomeGetStartedCheckbox.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  components: { CompositeCard, HomeGetStartedCheckbox },
  name: 'HomeGetStarted',
  props: {
    flags: {
      type: Object as PropType<{
        hasSolesPairs: boolean;
        hasCompletedWorkspace: boolean;
        hasPatient: boolean;
        hasFirstAnalysis: boolean;
      }>,
      required: true
    }
  },
  setup(properties, { root }) {
    const { flags } = toRefs(properties);

    const checkboxes = computed(() => {
      return [
        {
          label: root.$t('home.get-started.add-insoles'),
          isActive: flags.value.hasSolesPairs
        },
        {
          label: root.$t('home.get-started.workspace'),
          to: {
            name: 'settings'
          },
          isActive: flags.value.hasCompletedWorkspace
        },
        {
          label: root.$t('home.get-started.patient'),
          to: {
            name: 'patients',
            params: { createPatient: true }
          },
          isActive: flags.value.hasPatient,
          disabled: !root.$can('create', 'patient')
        },
        {
          label: root.$t('home.get-started.activity'),
          isActive: flags.value.hasFirstAnalysis
        }
      ];
    });

    return {
      checkboxes
    };
  }
});
